<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    ref="vDataTable"
    :headers="defaultHeaders"
    :search="internalSearch"
    class="dataTable"
    :class="{ 'mobile-table': isMobile }"
    :no-results-text="noResultsFound"
    :sort-by.sync="dataSortBy"
    :sort-desc.sync="dataSortDesc"
    :hide-default-header="isMobile"
    :disable-pagination="isMobile"
    :hide-default-footer="isMobile"
  >
    <!-- Default slot for search input -->
    <template #top="{ pagination }">
      <v-text-field
        v-model="internalSearch"
        prepend-inner-icon="mdi-magnify"
        :label="searchLabel"
        :aria-label="searchAriaLabel"
        class="py-4 px-8 searchInput"
        single-line
        hide-details
        clearable
      />
      <!-- Mobile custom header -->
      <template v-if="isMobile">
        <div class="table-top">
          <div class="table-top__sort-row">
            <div class="table-top__results grey--text text--lighten-2">
              <span class="primary--text">
                <strong>{{ pagination.itemsLength }}</strong> results
              </span>
            </div>
            <div class="table-top__sort">
              <SortOrderToggle v-model="dataSortDesc" class="mr-1" />
              <AdsSelect
                class="table-top__sort-by"
                :value.sync="dataSortBy"
                :items="sortByItems"
                aria-label="Sort By"
                :hide-details="true"
              />
            </div>
          </div>
        </div>
      </template>
    </template>
    <!-- Default slot for table headers -->
    <template v-for="(headerData, index) in headers" :slot="`header.${headerData.value}`">
      <span :key="index" class="headerBtn default-text" :ariaLabel="headerButtonAriaLabel(headerData)">{{
        headerData.text
      }}</span>
      <!--      <AdsButton-->
      <!--        v-if="addHeaderButtonAriaLabel"-->
      <!--        :key="index"-->
      <!--        class="headerBtn"-->
      <!--        tertiary-->
      <!--        small-->
      <!--        :buttonText="headerData.text"-->
      <!--        :ariaLabel="headerButtonAriaLabel(headerData)"-->
      <!--      />-->
      <!--      <AdsButton v-else :key="index" class="headerBtn" tertiary small :buttonText="headerData.text" />-->
    </template>
    <!--custom slots passed in to this component -->
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template v-for="(_, name) in $slots" #[name]>
      <slot :name="name" />
    </template>
    <!--  Mobile navigation (right arrow button) item slot  -->
    <template #[`item.mobileNav`]>
      <v-btn aria-label="Open details" icon>
        <v-icon class="mobile-row-nav" large> mdi-chevron-right </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
/**
 * Because AdsDataTable passes through $attrs and $listeners in multiple spots,
 * both the v-model for row selection, and for search bar input attach themselves to
 * the same passed in v-model. I had no other choice to circumvent this ADS bug
 * other than to have a local copy of the component without passing $attrs and $listeners to the search bar component.
 *
 * Thanks DDD team 👍🏾
 */

import { AdsSelect } from '@nswdoe/doe-ui-core';
import SortOrderToggle from '@nswdoe/doe-ui-core/src/SortOrderToggle';

export default {
  name: 'AdsDataTable',
  components: {
    AdsSelect,
    SortOrderToggle,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    searchLabel: {
      type: String,
      default: 'Search',
    },
    searchAriaLabel: {
      type: String,
      default: '',
    },
    addHeaderButtonAriaLabel: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: String,
      default: () => '',
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    noResultsFound: {
      type: String,
      default: () => 'No results found.',
    },
    search: {
      type: String,
      default: '',
    },
  },
  watch: {
    search(newValue) {
      this.dataSearch = newValue;
    },
    sortBy(newValue) {
      this.dataSortBy = newValue;
    },
  },
  data() {
    return {
      dataSearch: this.search,
      dataSortBy: this.sortBy,
      dataSortDesc: this.sortDesc,
    };
  },
  computed: {
    internalSearch: {
      get() {
        return this.dataSearch;
      },
      set(value) {
        this.dataSearch = value;
        this.$emit('update:search', value);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    defaultHeaders() {
      return this.isMobile ? [{ value: 'mobileNav', sortable: false }, ...this.headers] : this.headers;
    },
    sortByItems() {
      return this.defaultHeaders
        .filter(({ sortable }) => sortable !== false)
        .map(({ value, text }) => ({ value, text }));
    },
  },
  methods: {
    headerButtonAriaLabel(headerData) {
      // eg. The aria-label for the sort button in the header will be something like 'Student name. Activate to sort ascending'
      return `${headerData.text}. Activate to sort ${
        this.dataSortBy === headerData.value && !this.dataSortDesc ? 'descending' : 'ascending'
      }`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 1rem;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.v-data-table.dataTable {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.searchInput {
  border-bottom: 1px solid $ads-light-20;
  color: $ads-dark-60;

  ::v-deep .v-label {
    color: $ads-dark-60;
    font-size: 16px;
    font-weight: 400;
  }

  ::v-deep .v-icon {
    color: $ads-dark-60;
  }
}

.headerBtn {
  margin: 0;
  font-size: 1rem;
  border: none;
  padding: 0 !important;
  font-weight: normal;
  color: #041e42;
  word-wrap: normal;

  &:hover {
    color: #041e42;
    text-decoration: none;
  }

  &:hover::before {
    opacity: 0;
  }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th > button,
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: $ads-navy;
  font-weight: 400;
  font-size: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th.sortable.active button {
  font-weight: bold;
}

.theme--light.dataTable.v-data-table::v-deep {
  .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
    border-bottom: 0px;
  }
}

.theme--dark.v-data-table::v-deep .text-start {
  &:first-child {
    a {
      color: $ads-white;
    }
  }
}

.theme--light.dataTable.v-data-table::v-deep {
  .v-data-footer {
    .theme--light.v-btn.v-btn--icon {
      color: $ads-navy;
      // This is for the high contrast mode, for details, pls see FUS-21
      outline: 1px solid transparent !important;
    }

    .theme--light.v-btn.v-btn--icon:focus {
      opacity: 1;
      border: 3px solid $ads-navy !important;
    }
  }
}

::v-deep .v-data-footer {
  .v-data-footer__select .v-input.v-select {
    margin-left: 13px;
  }

  .v-data-footer__pagination {
    margin-right: 6px;
  }

  .v-data-footer__icons-before button.v-btn {
    margin-right: 3px;
  }

  .v-data-footer__icons-after button.v-btn {
    margin-left: 3px;
  }
}

.dataTable ::v-deep table {
  & td:not(.v-data-table__mobile-row),
  & thead:not(.v-data-table-header-mobile) th {
    &:first-child {
      //padding-left: 20px;
      min-width: 100px;
      max-width: 200px;
    }

    &:last-child {
      padding-right: 32px;
    }
  }

  tr td .v-data-table__mobile-row__header {
    display: none;
  }
}

.table-top,
::v-deep .table-top {
  padding: 1rem;
}

.table-top {
  &__sort-row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__sort {
    display: flex;
    align-items: center;
  }

  &__sort-by {
    padding-top: 0;

    &.v-input--is-focused ::v-deep .v-input__slot {
      border: 2px solid $ads-navy;
      border-radius: 3px;
    }

    ::v-deep input {
      width: 0;
    }

    & ::v-deep .v-input__slot {
      border: 2px solid transparent;
      margin-bottom: 0;
      padding-left: 0.25rem;
    }

    & ::v-deep .v-select__slot {
      width: auto;
    }

    & ::v-deep .v-select__selection--comma {
      font-weight: bold;
      overflow: visible;
      color: $ads-navy;
    }

    & ::v-deep .v-input__icon.v-input__icon--append .v-icon {
      color: $ads-navy !important;
    }
  }
}

// Mobile light mode
.theme--light.mobile-table {
  background-color: $ads-light-10 !important;

  & ::v-deep table {
    background-color: $ads-white;
  }

  & ::v-deep .searchInput {
    background-color: $ads-white;
  }

  & ::v-deep .v-data-table__mobile-table-row {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }

  & ::v-deep .mobile-row-nav {
    color: $ads-navy;
  }
}

// Mobile dark mode
.theme--dark.mobile-table {
  .primary--text {
    color: $ads-white !important;
  }

  & ::v-deep .searchInput {
    background-color: #363636;
    border-bottom: none;

    .v-label {
      color: $ads-white;
    }

    .v-icon {
      color: $ads-white;
    }
  }

  & ::v-deep .v-data-table__mobile-table-row {
    border-bottom: 1px solid $ads-dark-60;
  }

  & ::v-deep .mobile-row-nav {
    color: $ads-navy-dark;
  }

  & ::v-deep .v-select__selection--comma {
    color: $ads-white;
  }

  & ::v-deep .v-input__append-inner {
    .v-icon {
      color: $ads-navy-dark !important;
    }
  }

  & ::v-deep.v-data-table__mobile-row {
    &:nth-of-type(2) {
      a {
        color: $ads-white !important;
      }
    }
  }
}

::v-deep .v-data-table__mobile-table-row {
  padding: 0.75rem 0 1rem 0.5rem;
  display: inline-block !important;
  width: 100%;
  position: relative;

  .v-data-table__mobile-row {
    &:not(:first-child) {
      padding: 0.35rem 1rem;
      min-height: 1.75rem;

      & .v-data-table__mobile-row__cell {
        text-align: left;
        line-height: 1.5;
        height: 100%;
      }
    }

    &:first-child {
      height: 100%;
      position: absolute;
      top: 0;
      right: 1rem;
      padding: 0;
    }

    &:last-child {
      border-bottom: none !important;

      & .v-data-table__mobile-row__cell {
        width: 120px;
      }
    }
  }
}
</style>
