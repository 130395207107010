import { render, staticRenderFns } from "./ListScroller.vue?vue&type=template&id=63b0c232&scoped=true"
import script from "./ListScroller.vue?vue&type=script&lang=js"
export * from "./ListScroller.vue?vue&type=script&lang=js"
import style0 from "./ListScroller.vue?vue&type=style&index=0&id=63b0c232&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b0c232",
  null
  
)

export default component.exports