<template>
  <div aria-current="page" aria-label="Comparison">
    <Banner
      :iconSize="SIZES.BANNER_ICON.DESKTOP"
      contentClass="pl-3"
      defaultBackground1
      :heading="!mobile ? 'Benefits Calculator' : ''"
      icon="mdi-calculator"
      subtitle="Learn about the range of benefits and allowances for teachers and executives in NSW public schools."
    >
    </Banner>
    <ComparisonTable />
  </div>
</template>
<script>
import ComparisonTable from '@/components/ComparisonTable';
import { Banner } from '@nswdoe/doe-ui-core';
import { SIZES } from '@/constants';

export default {
  name: 'MapView',
  components: {
    ComparisonTable,
    Banner,
  },
  data() {
    return { SIZES };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style lang="scss" scoped>
// use pre-defined ADS scss variables
@import '../../scss/variables';

.ads-link {
  color: $ads-red-2-dark !important;
}

.info-window {
  position: absolute;
  width: 1000px;
  height: 80%;
  top: 15px;
  left: 0;
}

.banner__title {
  font-size: 40px;
  font-weight: 600;
  display: inline-block;
  padding: 0 12px;
  vertical-align: bottom;
}
</style>
