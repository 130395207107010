<template>
  <div class="d-flex flex-column">
    <v-container class="px-10">
      <v-row class="mt-3 mb-6" justify="end">
        <v-col class="d-flex justify-start" cols="12">
          <hgroup>
            <h1 class="headline grey-01--text">{{ selectedBenefit.details?.name }}</h1>
            <p class="mb-0 body-2 grey-02--text">{{ removeParenthesis(selectedBenefit.details?.description) }}</p>
          </hgroup>
        </v-col>
        <v-col>
          <AdsButton buttonText="Back to benefits" icon="mdi-arrow-left" tertiary @click="goToPreviousRoute" />
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-skeleton-loader
              v-if="isLoading || variants.length === 0"
              type="heading, table-row-divider, table-row@10, table-tfoot"
            />
            <SearchDataTable
              :headers="tableHeaders"
              :items="variants"
              :show-expand="!!selectedBenefit.variants[0]?.schoolCodes"
            >
              <template #[`item.schoolCodes`]="{ item }">
                {{ item.schoolCount }}
              </template>
              <template #[`item.isIncentive`]="{ item }">
                <BooleanIcon :value="item?.isIncentive" />
              </template>
              <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pl-8 py-4">
                  <div class="mb-2 subtitle-1 primary-blue--text">Schools</div>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-row dense>
                        <v-col v-for="(school, index) in item.schools" :key="index" cols="12" sm="6">
                          <span class="body-1 grey-01--text">{{
                            school.schoolFullName ?? `School not found for code ${school.schoolCode}`
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template #item.actions="{ item }">
                <v-icon small @click="openDeleteDialog(item.PK)"> mdi-delete</v-icon>
              </template>
            </SearchDataTable>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';

import BooleanIcon from '@/components/UI/BooleanIcon.vue';

import { mapActions, mapMutations, mapState } from 'vuex';
import SearchDataTable from '@/components/UI/SearchDataTable.vue';

export default {
  name: 'Variants',
  components: { SearchDataTable, AdsButton, BooleanIcon },
  data() {
    return {
      variantsFields: [
        { text: 'Variant', value: 'fullValueDescription', width: '25%' },
        { text: 'Total schools', value: 'schoolCodes' },
        { text: 'School population gender', value: 'schPopGender' },
        { text: 'Incentive', value: 'isIncentive' },
        { text: 'Transfer points', value: 'transferPoints' },
        { text: 'Socio-economic group', value: 'seGroup' },
        { text: 'Spouse type', value: 'spouseType' },
        { text: 'Children', value: 'children' },
        { text: 'Hot climatic allowance', value: 'isHot' },
        { text: 'Cold climatic allowance', value: 'isCold' },
      ],
      expanded: [],
    };
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('moduleAdmin', ['benefits', 'selectedBenefit']),
    variants() {
      return this.selectedBenefit.variants.map((variant, index) => {
        const value = variant?.value;
        let valueDenomination = this.selectedBenefit.details.valueDenomination ?? '';
        let valueDescription = this.selectedBenefit.details.valueDescription ?? '';
        let fullValueDescription;

        switch (true) {
          case valueDenomination?.includes('$'):
            fullValueDescription = `${valueDenomination}${
              isNaN(+value) ? 0 : (+value).toLocaleString()
            } ${valueDescription}`;
            break;
          case valueDenomination === '%':
            fullValueDescription = `${value ?? 0}${valueDenomination} ${valueDescription}`;
            break;
          case !!valueDenomination:
            valueDenomination = value > 1 || !value ? `${valueDenomination}s` : valueDenomination;
            fullValueDescription = `${value ?? 0} ${valueDenomination} ${valueDescription}`;
            break;
          case !valueDenomination:
            valueDenomination = '';
            fullValueDescription = value ?? 'No';
            break;
          default:
        }

        let schoolCount = 0;
        if (variant.schoolCodes?.length > 0) {
          schoolCount = variant.schoolCodes?.length;
        }

        return { id: index, ...variant, fullValueDescription, schoolCount };
      });
    },
    tableHeaders() {
      if (this.variants[0]) {
        const matchedKeys = Object.keys(this.variants[0]).filter((key) =>
          this.variantsFields.some((item) => item.value === key)
        );
        const filteredFields = this.variantsFields.filter((item) => matchedKeys.includes(item.value));
        return this.variants[0]?.schoolCount > 0
          ? [...filteredFields, { text: '', value: 'data-table-expand' }]
          : [...filteredFields];
      }
      return [];
    },
  },
  methods: {
    ...mapActions('moduleAdmin', ['getBenefits']),
    ...mapMutations('moduleAdmin', ['SET_SELECTED_BENEFIT', 'RESET_SELECTED_BENEFIT']),
    goToPreviousRoute() {
      this.$router.go(-1);
    },
    removeParenthesis(str) {
      if (!str) return '';
      let textWithoutParenthesis;
      if (str.charAt(0) === '(' && str.charAt(str.length - 1) === ')') {
        textWithoutParenthesis = str.slice(1, -1);
      } else textWithoutParenthesis = str;
      return textWithoutParenthesis.charAt(0).toUpperCase() + textWithoutParenthesis.slice(1);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.RESET_SELECTED_BENEFIT();
    next();
  },
};
</script>

<style></style>
