import api from '@/api/modules/school';
import { addNSWBeforePostCode } from '@/helpers/utils';

const moduleResults = {
  namespaced: true,
  state: {
    results: {
      benefitDetails: [],
      schools: [],
      selectedSchool: undefined,
    },
    filters: {
      isIncentiveOnly: false,
      isConnectedCommunityOnly: false,
      selectedBenefits: [],
      selectedLevel: [],
      selectedSize: [],
      selectedWorkType: [],
      selectedPoints: [],
      selectedAllowance: [],
      selectedType: [],
      searchValue: '',
    },
  },
  getters: {
    results: (state) => {
      return state.results;
    },
    filter: (state) => state.filters,
  },
  mutations: {
    SET_BENEFIT_DETAILS(state, result) {
      state.results.benefitDetails = result;
    },
    SET_SCHOOLS(state, result) {
      state.results.schools = result;
    },
    SET_SELECTED_SCHOOL(state, result) {
      state.results.selectedSchool = result;
    },
    SET_RESULTS(state, result) {
      state.results = result;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
  },
  actions: {
    fetchResults({ commit, rootState }, formValues) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true });
        commit('SET_IS_LOADED', false, { root: true });
      }
      const updateList = rootState.compareList.map((item) =>
        api.getSchoolsWithCalculatedBenefits({ ...formValues, schoolCode: item.schoolCode })
      );
      commit('SET_RESULTS', {
        benefitDetails: [],
        schools: [],
        selectedSchool: undefined,
      });
      commit('RESET_COMPARE_LIST', true, { root: true });

      Promise.all([api.getSchoolsWithCalculatedBenefits(formValues), ...updateList])
        .then((response) => {
          const results = response[0];
          commit('SET_BENEFIT_DETAILS', results.benefitDetails);
          commit(
            'SET_SCHOOLS',
            results.schools
              .map((school) => {
                const fullAddress = addNSWBeforePostCode(school.address);
                return {
                  ...school,
                  fullAddress,
                };
              })
              .sort((a, b) => a.schoolFullName.localeCompare(b.schoolFullName))
          );
          for (const compareSchool of response.slice(1)) {
            const { benefitDetails: benefitReferences, schools } = compareSchool;
            const schoolBenefitItems = schools[0]?.benefits.benefitItems.map((item) => {
              const matchedBenefit = benefitReferences.find((benefitDetailsItem) => {
                return item.benefitCode === benefitDetailsItem.benefitCode;
              });
              if (matchedBenefit) {
                return {
                  ...matchedBenefit,
                  value: item.value,
                };
              }
            });
            const schoolWithCalculatedBenefits = schools[0];
            schoolWithCalculatedBenefits.benefits.benefitItems = schoolBenefitItems;
            commit('ADD_TO_COMPARE', schoolWithCalculatedBenefits, { root: true });
          }
        })
        .finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, { root: true });
            commit('SET_IS_LOADED', true, { root: true });
          }
        });
    },
    fetchSelectedResult({ commit, rootState }, formValues) {
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, { root: true });
      }
      commit('SET_SELECTED_SCHOOL', undefined);
      api
        .getSchoolsWithCalculatedBenefits(formValues)
        .then((response) => {
          commit('SET_BENEFIT_DETAILS', response.benefitDetails);
          commit('SET_SELECTED_SCHOOL', response.schools?.length === 1 ? response.schools[0] : undefined);
        })
        .finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, { root: true });
          }
          commit('SET_SHOWMODAL', true, { root: true });
        });
    },
    resetResults({ commit }) {
      commit('SET_RESULTS', {
        benefitDetails: [],
        schools: [],
        selectedSchool: undefined,
      });
      commit('RESET_COMPARE_LIST', true, { root: true });
    },
    updateFilters({ commit }, filters) {
      commit('SET_FILTERS', filters);
    },
  },
};
export default moduleResults;
