<template>
  <div class="d-flex align-center alertbox py-4 px-2 mx-4 my-4">
    <v-icon :color="ADS_Colors.PRIMARY_BLUE" size="27" class="mr-4"> mdi-information </v-icon>
    <div class="snackbar--text font-weight-bold">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core';
export default {
  name: 'AlertBox',
  data() {
    return {
      ADS_Colors,
    };
  },
};
</script>
<style scoped lang="scss">
.alertbox {
  background: #e5f6fe;
  border-left: solid 8px #002664;
  border-radius: 5px;
  box-shadow: 0 1px 2px #a0a5ae;
}
</style>
