<template>
  <div class="flex-column">
    <v-form ref="form" v-model="valid">
      <fieldset>
        <legend class="subtitle-1">Do you have a spouse or partner?</legend>
        <v-radio-group v-model="formValues.isPartner" :rules="spouseRequired" required class="mt-2">
          <div class="d-flex">
            <div>
              <v-radio label="Yes" value="true" />
            </div>
            <div class="pl-3">
              <v-radio label="No" value="false" />
            </div>
          </div>
        </v-radio-group>
      </fieldset>
      <AdsSelect
        v-if="formValues.isPartner === 'true'"
        v-model="formValues.partnerType"
        :class="`pb-0`"
        :items="[
          { text: 'Dependent', value: 'DEP' },
          { text: 'Non dependent', value: 'NON-DEP' },
          { text: 'NSW public sector teacher', value: 'DOE' },
        ]"
        :rules="spouseRequired"
        :style="`width: ${mobile ? '90vw' : ''}`"
        label="Which applies to your spouse or partner? (required)"
        required
      />
      <AdsSelect
        v-model="formValues.dependents"
        :items="[
          {
            text: '0',
            value: 0,
          },
          {
            text: '1',
            value: 1,
          },
          {
            text: '2',
            value: 2,
          },
          {
            text: '3',
            value: 3,
          },
          {
            text: '4',
            value: 4,
          },
          {
            text: '5',
            value: 5,
          },
          {
            text: '6',
            value: 6,
          },
          {
            text: '7',
            value: 7,
          },
          {
            text: '8+',
            value: 8,
          },
        ]"
        :rules="dependentsRequired"
        class="pb-0"
        label="Select number of dependents (required)"
        required
      />
      <div class="grow text-right">
        <AdsButton v-if="showReset" class="mr-2" tertiary @click="reset"> Reset </AdsButton>
        <AdsButton :buttonText="isUserSearch ? 'Update results' : 'See results'" @click="validate"></AdsButton>
      </div>
    </v-form>
  </div>
</template>

<script>
import { AdsButton, AdsSelect } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchFields',
  components: {
    AdsSelect,
    AdsButton,
  },
  data() {
    return {
      itemsTeachFor: ['Less than 1 year', '1 Year', '2 Years', '3 Years', '4 Years', '5 Years or more'],
      formValues: {
        isPartner: null,
        partnerType: 'NONE',
        dependents: null,
      },
      valid: false,
      spouseRequired: [(v) => !!v || v === 0 || 'Spouse type is required'],
      dependentsRequired: [(v) => !!v || v === 0 || 'Number of dependents is required'],
    };
  },
  computed: {
    ...mapGetters({
      formInfo: 'formValues',
      isUserSearch: 'isUserSearch',
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  props: {
    showReset: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  mounted() {
    this.formValues = this.$store.getters.formValues;
  },
  watch: {
    formInfo(val) {
      this.formValues = val;
    },
  },
  methods: {
    async validate() {
      this.$refs.form.validate();
      if (this.valid === true) {
        this.$emit('buttonClick');
        await this.$store.dispatch('moduleResults/fetchResults', {
          spouse: this.formValues.partnerType ?? 'NONE',
          children: this.formValues.dependents,
        });
        await this.$store.dispatch('searchFormInfo', this.formValues);
      }
    },
    async reset() {
      this.$emit('buttonClick');
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      await this.$store.dispatch('moduleResults/resetResults');
      await this.$store.dispatch('resetForm');
    },
  },
};
</script>

<style scoped lang="scss">
fieldset {
  border: none;
}
</style>
