export const SCROLLER_PAGE_SIZE = parseInt(process.env.VUE_APP_SCROLLER_PAGE_SIZE as string) ?? 20;

const COLOURS = {
  INCENTIVE: {
    background: '#CBEDFD',
    text: '#002664',
  },
  CC: {
    background: '#EBEBEB',
    text: '#22272B',
  },
  RENTAL_SUBSIDY: {
    background: '#e6e6e6',
    text: '#000000',
  },
  VALUE: { text: '#495054' },
};

const SIZES = {
  BANNER: {
    MINIMISED: 60,
    DESKTOP: 118,
    MOBILE: 160,
  },
  BANNER_ICON: {
    DESKTOP: '75px',
    MOBILE: '30px',
  },
  SEARCH_RESULTS_HEIGHT: {},
};

export { COLOURS, SIZES };

export const BENEFIT_CATEGORIES = {
  major: 'Major Financial Benefit',
  professional: 'Professional Benefit',
  additional: 'Available Additional Benefit',
};

export const MAJOR_BENEFITS_CHIP_FILTER_CODES = [10010, 10020, 10030, 10040, 10050, 10060, 10061, 10070];

export const ALLOWANCES_CHIP_FILTER_CODES = [
  2056, 2236, 2235, 2242, 2244, 2246, 2248, 2250, 2252, 2254, 2256, 2258, 2260, 10120, 30020, 30010, 30011, 30012,
  10110,
];

export const CHIP_FILTER_SCHOOL_LEVELS = [
  {
    id: 'Primary school',
    name: 'Primary school',
  },
  {
    id: 'Secondary school',
    name: 'Secondary school',
  },
  {
    id: 'Central/Community school',
    name: 'Central/Community school',
  },
  {
    id: 'Environmental Education Centre',
    name: 'Environmental Education Centre',
  },
  {
    id: 'Infants school',
    name: 'Infants school',
  },
  {
    id: 'Schools for Specific Purposes',
    name: 'Schools for Specific Purposes',
  },
];

export const CHIP_FILTER_TRANSFER_POINTS = [
  {
    id: 1,
    name: '1',
  },
  {
    id: 2,
    name: '2',
  },
  {
    id: 4,
    name: '4',
  },
  {
    id: 6,
    name: '6',
  },
  {
    id: 8,
    name: '8',
  },
];

export const CHIP_FILTER_SCHOOL_SIZES = [
  {
    id: 100,
    name: 'Up to 100 enrolments',
  },
  {
    id: 200,
    name: 'Up to 200 enrolments',
  },
  {
    id: 300,
    name: 'Up to 300 enrolments',
  },
  {
    id: 400,
    name: 'Up to 400 enrolments',
  },
  {
    id: 500,
    name: 'Up to 500 enrolments',
  },
  {
    id: 501,
    name: '500+ enrolments',
  },
];

export const CHIP_FILTER_SCHOOL_TYPES = [
  {
    id: 'Incentive school',
    name: 'Incentive school',
  },
  {
    id: 'Connected community school',
    name: 'Connected community school',
  },
];
