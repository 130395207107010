import { render, staticRenderFns } from "./AlertBox.vue?vue&type=template&id=09a39b5b&scoped=true"
import script from "./AlertBox.vue?vue&type=script&lang=js"
export * from "./AlertBox.vue?vue&type=script&lang=js"
import style0 from "./AlertBox.vue?vue&type=style&index=0&id=09a39b5b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09a39b5b",
  null
  
)

export default component.exports