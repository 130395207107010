<template>
  <svg
    :class="'essential icon ' + getIconClass(appName)"
    :style="getDimensions()"
    :id="'svg-' + svgId"
    viewBox="0 0 58 58"
    aria-label="NSW Government Logo"
  >
    <title>NSW Government Logo</title>
    <use :xlink:href="'#' + appName" />
  </svg>
</template>

<script>
const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default {
  name: 'AppIcon',
  props: {
    appName: {
      type: String,
      default: 'my-nsw-gov',
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '40px',
    },
  },
  data() {
    return {
      svgId: null,
      isInternetExplorer: document.documentMode != null,
    };
  },
  created() {
    this.svgId = this._uid;
  },
  mounted() {
    if (this.isInternetExplorer) {
      this.renderIconForIe();
    }
  },
  methods: {
    getIconClass(appName) {
      if (!this.transparent) {
        // iconToClass function is included from essentialIconsMap.js on index.html
        if (window.iconToClass) {
          return window.iconToClass[appName];
        }
      }
    },
    getDimensions() {
      const dim = this.size ? this.size : '40px';
      return { width: dim, height: dim };
    },
    copyElement(original, newTagName) {
      // Create a replacement tag of the desired type
      let copiedElement = document.createElement(newTagName);

      // Grab all of the original's attributes, and pass them to the copiedElement
      if (original && original.attributes) {
        for (let i = 0; i < original.attributes.length; ++i) {
          let nodeName = original.attributes.item(i).nodeName;
          let nodeValue = original.attributes.item(i).nodeValue;

          copiedElement.setAttribute(nodeName, nodeValue);
        }

        // Persist contents
        for (let c = 0; c < original.childNodes.length; c++) {
          copiedElement.appendChild(original.childNodes[c].cloneNode(true));
        }
      }

      return copiedElement;
    },
    renderIconForIe: async function () {
      let knownIcon = document.getElementById('my-generic-blank');
      let retryCount = 0;
      while (!knownIcon && retryCount < 8) {
        // try and wait for icon svg div to load if not already available
        await sleep(1000);
        knownIcon = document.getElementById('my-generic-blank');
        retryCount++;
      }
      if (!knownIcon) {
        return;
      }

      let appName = this.appName;
      let svg = this.copyElement(document.getElementById(appName), 'div');
      const mySvg = document.getElementById('svg-' + this.svgId);
      mySvg.removeChild(mySvg.childNodes[0]);
      for (let i = 0; i < svg.childNodes.length; i++) {
        mySvg.appendChild(svg.childNodes[i].cloneNode(true));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.essential,
svg {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 0.2rem;
}
</style>
