import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import MapView from '@/views/Home/index.vue';
import CompareView from '@/views/Comparison/index.vue';
import Admin from '@/views/Admin/index.vue';
import Variants from '@/views/Admin/Variants/index.vue';
import SchoolForm from '@/views/Admin/SchoolForm/index.vue';
import Manage from '@/views/Admin/Manage/index.vue';
import NotFound from '@/views/NotFound/index.vue';
import moduleResults from '@/store/modules/results';
import { checkIsAdmin } from '@/helpers/utils';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: MapView,
  },
  {
    path: '/comparison',
    name: 'Comparison',
    component: CompareView,
    beforeEnter(to, from, next) {
      const schools = moduleResults.state.results.schools;
      if (schools && schools.length > 0) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    redirect: { name: 'Manage' },
    children: [
      {
        path: 'manage',
        name: 'Manage',
        component: Manage,
        meta: {
          parentRouteName: 'Admin',
          protected: true,
        },
        async beforeEnter(to, from, next) {
          const userProfile = Vue.prototype?.$OAuth?.profile;
          if (userProfile) {
            checkIsAdmin(userProfile) ? next() : next('/404');
          } else {
            next('/404');
          }
        },
      },
      {
        path: 'schools/:schoolCode',
        name: 'editSchool',
        component: SchoolForm,
        meta: {
          parentRouteName: 'Admin',
          protected: true,
        },
        async beforeEnter(to, from, next) {
          const userProfile = Vue.prototype?.$OAuth?.profile;
          if (userProfile) {
            checkIsAdmin(userProfile) ? next() : next('/404');
          } else {
            next('/404');
          }
        },
      },
      {
        path: 'benefits/:benefitCode',
        name: 'Variants',
        component: Variants,
        meta: {
          parentRouteName: 'Admin',
          protected: true,
        },
        async beforeEnter(to, from, next) {
          const userProfile = Vue.prototype?.$OAuth?.profile;
          if (userProfile) {
            checkIsAdmin(userProfile) ? next() : next('/404');
          } else {
            next('/404');
          }
        },
      },
    ],
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  const matchedRoute = router.match(to.path);
  if (matchedRoute.matched.length === 0) {
    next('/404');
  } else {
    next();
  }
});

export default router;
