<template>
  <v-card aria-label="Search Results" class="pt-0 d-flex" flat>
    <div v-if="isUserSearch" class="d-flex flex-column mt-0" :style="`${mobile ? 'width: 100vw' : ''}`">
      <div ref="fieldsElement">
        <v-menu v-model="menu" :close-on-content-click="false" :offset-y="true">
          <template #activator="{ on, attrs }">
            <v-row :class="mobile ? 'pa-2' : ''" no-gutters>
              <v-col class="pl-6 pr-2 py-4">
                <AdsTextField
                  ref="searchSchoolRegion"
                  v-model="searchRegion"
                  hide-details="auto"
                  label="Search school or region"
                  placeholder="Search school or region"
                  prepend-inner-icon="search"
                  @blur="
                    () => {
                      updateFilters();
                    }
                  "
                />
              </v-col>
              <v-col :class="contentWidth === '475px' ? 'col-3' : 'col-2'" class="pr-6 pl-2 py-4">
                <v-btn
                  class="detailsButton"
                  outlined
                  style="height: 56px; border: 1px solid #ccc"
                  title="Employee details"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="pr-1" style="border-right: 1px solid #ccc"> person_outline</v-icon>
                  <v-icon v-if="!menu" class="pl-1"> arrow_drop_down</v-icon>
                  <v-icon v-else class="pl-1"> arrow_drop_up</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <FocusTrap isActive>
            <v-card aria-label="Employee details" class="pa-5 flex-column grow">
              <div class="d-flex justify-space-between pb-2">
                <div class="d-flex flex-row align-center">
                  <FeatureIcon :backgroundColor="`#caedfd`" class="ml-4 mr-2" icon="person_outline" />
                  <div class="title">Employee details</div>
                </div>
                <v-btn aria-label="Close menu pop-up" class="mt-n4" icon @click="closeMenu">
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
              <div class="grow">
                <SearchFields
                  :class="`pb-2 ${mobile ? 'pl-2 mt-2 pr-2' : 'ml-5 mt-2 mr-5'}`"
                  @buttonClick="closeMenu"
                />
              </div>
            </v-card>
          </FocusTrap>
        </v-menu>
        <div v-if="isUserSearch">
          <div v-if="!mobile || small" class="d-flex flex-wrap px-5">
            <ChipFilterModified
              key="filter_1"
              v-model="benefitsModal"
              :items="typeBenefits"
              class="pa-1"
              heading="Benefits"
              name="Benefits"
              type="checkbox"
            />
            <ChipFilterModified
              key="filter_2"
              v-model="levelModal"
              :items="schoolLevel"
              class="pa-1"
              heading="School level"
              name="School level"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedLevel');
                }
              "
            />
            <ChipFilterModified
              key="filter_3"
              v-model="sizeModal"
              :items="sizeSchool"
              class="pa-1"
              heading="School size"
              name="School size"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedSize');
                }
              "
            />
            <ChipFilterModified
              key="filter_4"
              v-model="pointsModal"
              :items="typePoints"
              class="pa-1"
              heading="Transfer points"
              name="Transfer points"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedPoints');
                }
              "
            />
            <ChipFilterModified
              key="filter_5"
              v-model="allowancesModal"
              :items="typeAllowances"
              class="pa-1"
              heading="Allowances"
              name="Allowances"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedAllowance');
                }
              "
            />
            <ChipFilterModified
              key="filter_6"
              v-model="typeModal"
              :items="schoolType"
              class="pa-1"
              heading="School type"
              name="School type"
              offset-x
              style="z-index: unset"
              type="checkbox"
              @change="
                () => {
                  updateFilters('selectedType');
                }
              "
            />
          </div>
          <v-slide-group v-else-if="mobile" :show-arrows="false" class="pa-2 d-flex flex-wrap" hide-delimiters>
            <v-slide-item key="slide_1">
              <ChipFilterModified
                key="filter_1"
                v-model="benefitsModal"
                :items="typeBenefits"
                heading="Benefits"
                name="Benefits"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedBenefits');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_2">
              <ChipFilterModified
                key="filter_2"
                v-model="levelModal"
                :items="schoolLevel"
                heading="School type"
                name="School type"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedLevel');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_3">
              <ChipFilterModified
                key="filter_3"
                v-model="sizeModal"
                :items="sizeSchool"
                heading="School size"
                name="School size"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedSize');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_4">
              <ChipFilterModified
                key="filter_4"
                v-model="pointsModal"
                :items="typePoints"
                heading="Transfer points"
                name="Transfer points"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedPoints');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_5">
              <ChipFilterModified
                key="filter_5"
                v-model="allowancesModal"
                :items="typeAllowances"
                heading="Allowances"
                name="Allowances"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedAllowance');
                  }
                "
              />
            </v-slide-item>
            <v-slide-item key="slide_6">
              <ChipFilterModified
                key="filter_6"
                v-model="typeModal"
                :items="schoolType"
                heading="School Status"
                name="School Status"
                offset-x
                style="z-index: unset"
                type="checkbox"
                @change="
                  () => {
                    updateFilters('selectedType');
                  }
                "
              />
            </v-slide-item>
          </v-slide-group>
          <div v-if="schoolResults?.length === 0" :class="`${mobile ? 'pa-2' : 'pa-5'} pt-0 pb-0`">
            <div v-if="isLoaded" class="pt-2 subtitle-1 text-center" role="alert">
              0 results matching your search and filters
            </div>
          </div>
          <div v-else-if="schoolResults?.length > 0" class="px-6 pt-6 pb-4">
            <div class="d-flex justify-space-between align-end">
              <div v-if="isLoaded" class="default-text font-weight-bold" role="alert">
                {{ schoolResults.length }}
                results matching your search and filters
              </div>
              <div v-if="isLoaded && schoolResults?.length > 0">
                <AdsButton
                  ref="compareButton"
                  :disabled="numberOfCompare === 0"
                  aria-label="Go to Comparison Page"
                  primary
                  @click="showComparisonTableAction()"
                >
                  <div>Compare</div>
                  <div
                    v-if="numberOfCompare < 5"
                    style="
                      background: #fff;
                      padding: 5px;
                      margin-left: 5px;
                      color: #041e42;
                      border-radius: 50%;
                      min-width: 28px;
                      min-height: 25px;
                    "
                  >
                    {{ numberOfCompare }}
                  </div>
                  <div
                    v-else
                    style="
                      background: #fff;
                      padding: 5px;
                      margin-left: 5px;
                      color: #041e42;
                      border-top-right-radius: 1em;
                      border-bottom-right-radius: 1em;
                      border-top-left-radius: 1em;
                      border-bottom-left-radius: 1em;
                      min-width: 70px;
                      min-height: 25px;
                    "
                  >
                    {{ numberOfCompare }}
                    (max)
                  </div>
                </AdsButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isUserSearch"
        :class="`${mobile ? 'pa-0' : 'pa-5'} pt-0 pb-0`"
        :style="`border-top: 1px solid #ccc ${mobile ? 'width: 100vw' : ''}`"
      >
        <ListScroller
          v-if="!tabs"
          :contentWidth="contentWidth"
          :results="schoolResults"
          :style="`height: ${scrollerHeight}px`"
        />
        <v-tabs
          v-else-if="tabs"
          ref="tabNavElement"
          v-model="tab"
          class="ma-0 pa-0"
          fixedTabs
          slider-color="red"
          :style="'width: 100vw'"
        >
          <v-tab v-for="(item, index) in NAV_ITEMS" :key="`tab${index}`" class="default-text label-text">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless :style="`${mobile ? 'width: 100vw' : ''}`">
          <v-tab-item key="tab1">
            <ListScroller :results="schoolResults" :style="`height: ${scrollerHeight}px`" />
          </v-tab-item>
          <v-tab-item key="tab2">
            <MapView
              :mapHeight="scrollerHeight"
              :style="`height: ${scrollerHeight}px; -webkit-height: ${scrollerHeight}px; width: 100vw`"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <div
      v-if="!isUserSearch"
      :aria-hidden="isUserSearch"
      :style="`height: ${scrollerHeight}px`"
      class="pa-5 ma-0 grow flex-column"
    >
      <div class="mb-2 d-flex flex-row align-center">
        <FeatureIcon :backgroundColor="`#caedfd`" class="mr-2" icon="person_outline" />
        <div class="title">Employee details</div>
      </div>
      <SearchFields ref="searchFields" :showReset="false" />
    </div>
  </v-card>
</template>
<script>
import { AdsButton, AdsTextField, FeatureIcon, FocusTrap } from '@nswdoe/doe-ui-core';
import SearchFields from './SearchFields.vue';
import ChipFilterModified from '@/components/ChipFilterModified';
import { mapGetters, mapState } from 'vuex';
import {
  ALLOWANCES_CHIP_FILTER_CODES,
  CHIP_FILTER_SCHOOL_LEVELS,
  CHIP_FILTER_SCHOOL_SIZES,
  CHIP_FILTER_SCHOOL_TYPES,
  CHIP_FILTER_TRANSFER_POINTS,
  MAJOR_BENEFITS_CHIP_FILTER_CODES,
} from '@/constants';
import { getBenefitChipFilterOptions } from '@/helpers/utils';
import ListScroller from '@/components/SearchResults/ListScroller.vue';
import MapView from '@/components/googleMapLoader/MapView.vue';

export default {
  name: 'SearchResults',
  components: {
    MapView,
    ListScroller,
    AdsTextField,
    SearchFields,
    ChipFilterModified,
    AdsButton,
    FocusTrap,
    FeatureIcon,
  },
  props: {
    contentWidth: {
      type: String,
      default: '475px',
    },
    results: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabs: {
      type: Boolean,
      default: false,
    },
    topElementHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      page: 1,
      tab: null,
      menu: false,
      scrollerHeight: 0,
      tabBarHeight: 0,
      NAV_ITEMS: [{ tab: 'LIST VIEW' }, { tab: 'MAP VIEW' }],
      schoolLevel: CHIP_FILTER_SCHOOL_LEVELS,
      sizeSchool: CHIP_FILTER_SCHOOL_SIZES,
      benefitsModal: [],
      filters: {
        isIncentiveOnly: false,
        isConnectedCommunityOnly: false,
        selectedBenefits: [],
        selectedLevel: [],
        selectedSize: [],
        selectedWorkType: [],
        selectedPoints: [],
        selectedAllowance: [],
        selectedType: [],
        searchValue: '',
      },
      searchRegion: '',
      sizeModal: [],
      typeModal: [],
      allowancesModal: [],
      levelModal: [],
      pointsModal: [],
      searchModal: '',
      searchIntervalId: undefined,
      typePoints: CHIP_FILTER_TRANSFER_POINTS,
      schoolType: CHIP_FILTER_SCHOOL_TYPES,
    };
  },
  computed: {
    ...mapState('moduleResults', { storeResults: 'results' }),
    ...mapGetters(['isUserSearch', 'numberOfCompare', 'isLoaded']),
    typeBenefits() {
      return getBenefitChipFilterOptions(MAJOR_BENEFITS_CHIP_FILTER_CODES, this.allBenefits);
    },
    typeAllowances() {
      return getBenefitChipFilterOptions(ALLOWANCES_CHIP_FILTER_CODES, this.allBenefits);
    },
    allBenefits() {
      const unifiedBenefitsCodes = this.results.flatMap((school) => school.benefits.benefitCodes);
      const uniqueBenefitsCodes = [...new Set(unifiedBenefitsCodes)];
      return uniqueBenefitsCodes.map((benefitCode) => {
        return this.storeResults.benefitDetails.find((benefit) => {
          return benefit.benefitCode === benefitCode;
        });
      });
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    small() {
      return this.$vuetify.breakpoint.sm;
    },
    schoolResults() {
      let results = this.results;
      const filters = this.filters;
      if (filters.searchValue) {
        const filterRegion = filters.searchValue.toLowerCase();
        results = results.filter(
          (result) =>
            result.address.toLowerCase().indexOf(filterRegion) !== -1 ||
            result.schoolFullName.toLowerCase().includes(filterRegion) ||
            result.region.toLowerCase().includes(filterRegion)
        );
      }
      if (filters.selectedPoints.length) {
        results = results.filter((result) => filters.selectedPoints.includes(result.transferPoints));
      }
      if (filters.selectedLevel.length) {
        const selectedLevels = filters.selectedLevel.map((schoolLevel) => schoolLevel.toLowerCase());
        results = results.filter((result) => selectedLevels.includes(result.schoolLevel.toLowerCase()));
      }
      if (filters.selectedSize.length) {
        const sortedSize = filters.selectedSize.sort((a, b) => b - a)[0];
        if (sortedSize > 500) {
          results = results.filter((result) => result.studentNumber > 500);
        } else results = results.filter((result) => result.studentNumber <= sortedSize);
      }
      if (filters.selectedAllowance.length) {
        const lowerCaseSelectedAllowance = filters.selectedAllowance.map((item) => item.toLowerCase());
        const schoolsWithBenefitsNames = results.map((school) => {
          const benefitsDetails = school.benefits.benefitCodes.map((code) =>
            this.storeResults.benefitDetails.find((benefit) => benefit.benefitCode === code)
          );
          return {
            ...school,
            benefitsDetails,
          };
        });
        results = schoolsWithBenefitsNames.filter((school) => {
          return school.benefitsDetails.some(
            (item) => lowerCaseSelectedAllowance.indexOf(item.name.toLowerCase()) !== -1
          );
        });
      }
      if (filters.selectedBenefits.length) {
        const lowerCaseSelectedBenefits = filters.selectedBenefits.map((item) => item.toLowerCase());
        const schoolsWithBenefitsNames = results.map((school) => {
          const benefitsDetails = school.benefits.benefitCodes.map((code) =>
            this.storeResults.benefitDetails.find((benefit) => benefit.benefitCode === code)
          );
          return {
            ...school,
            benefitsDetails,
          };
        });
        results = schoolsWithBenefitsNames.filter((school) => {
          return school.benefitsDetails.some(
            (item) => lowerCaseSelectedBenefits.indexOf(item.name.toLowerCase()) !== -1
          );
        });
      }
      if (filters.isIncentiveOnly) {
        results = results.filter((result) => result.isIncentive === true);
      }
      if (filters.isConnectedCommunityOnly) {
        results = results.filter((result) => result.isCC);
      }
      if (filters.selectedType.length) {
        const isIncentiveSchool = filters.selectedType.some((item) => item.toLowerCase() === 'incentive school');
        const isConnectedCommunitySchool = filters.selectedType.some(
          (item) => item.toLowerCase() === 'connected community school'
        );
        results = results.filter(
          (result) =>
            (result.isIncentive === true && isIncentiveSchool) || (result.isCC === true && isConnectedCommunitySchool)
        );
      }
      return results;
    },
  },
  created() {
    this.resetFilters();
  },
  updated() {
    this.$nextTick(() => {
      if (this.mobile) {
        this.scrollerHeight = window.innerHeight - this.topElementHeight;
      } else {
        this.scrollerHeight =
          window.innerHeight - this.topElementHeight - (this.$refs.fieldsElement?.clientHeight ?? 0) - 1;
      }
    });
  },
  watch: {
    topElementHeight: {
      handler() {
        this.$nextTick(() => {
          if (this.$refs.fieldsElement) {
            if (this.mobile) {
              this.scrollerHeight = window.innerHeight - this.topElementHeight;
            } else {
              this.scrollerHeight =
                window.innerHeight - this.topElementHeight - (this.$refs.fieldsElement?.clientHeight ?? 0) - 1;
            }
          }
        });
      },
      immediate: true,
    },
    benefitsModal(value, oldValue) {
      if (oldValue != null && value !== oldValue) {
        this.filters.selectedBenefits = value;
        this.updateFilters();
      }
    },
    levelModal(value, oldValue) {
      if (value !== oldValue) {
        this.filters.selectedLevel = value;
        this.updateFilters();
      }
    },
    sizeModal(value, oldValue) {
      if (value !== oldValue) {
        this.filters.selectedSize = value;
        this.updateFilters();
      }
    },
    pointsModal(value, oldValue) {
      if (value !== oldValue) {
        this.filters.selectedPoints = value;
        this.updateFilters();
      }
    },
    allowancesModal(value, oldValue) {
      if (value !== oldValue) {
        this.filters.selectedAllowance = value;
        this.updateFilters();
      }
    },
    typeModal(value, oldValue) {
      if (value !== oldValue) {
        this.filters.selectedType = value;
        this.updateFilters();
      }
    },
    tabNavHeight(val) {
      if (!this.tabBarHeight) {
        this.tabBarHeight = document.documentElement.clientHeight + val;
        this.$emit('scrollHeight', this.tabBarHeight);
      }
    },
    numberOfCompare(newValue) {
      if (newValue === 5) {
        this.$refs.compareButton.$el.focus();
      }
    },
    searchRegion(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.debounceSearch(newVal);
      }
    },
  },
  methods: {
    debounceSearch(value) {
      if (this.searchIntervalId) {
        clearTimeout(this.searchIntervalId);
        this.searchIntervalId = undefined;
      }
      this.searchIntervalId = setTimeout(() => {
        this.filters.searchValue = value;
      }, 500);
    },
    getFilterName(filterKey) {
      const filtersOptions = [
        {
          key: 'selectedBenefits',
          value: 'Benefits',
        },
        {
          key: 'selectedLevel',
          value: 'school_level',
        },
        {
          key: 'selectedSize',
          value: 'school_size',
        },
        {
          key: 'selectedPoints',
          value: 'transfer_points',
        },
        {
          key: 'selectedAllowance',
          value: 'allowances',
        },
        {
          key: 'selectedType',
          value: 'school_type',
        },
      ];
      return filtersOptions.find((option) => option.key === filterKey).value;
    },
    updateFilters() {
      this.$store.commit('SET_IS_LOADING', true);
      this.$store.commit('SET_IS_LOADED', false);
      this.$store.dispatch('moduleResults/updateFilters', this.filters);
      this.$nextTick(() => {
        this.$store.commit('SET_IS_LOADING', false);
        this.$store.commit('SET_IS_LOADED', true);
      });
    },
    resetFilters() {
      this.$store.dispatch('moduleResults/updateFilters', {
        isIncentiveOnly: false,
        isConnectedCommunityOnly: false,
        selectedBenefits: [],
        selectedLevel: [],
        selectedSize: [],
        selectedWorkType: [],
        selectedPoints: [],
        selectedAllowance: [],
        selectedType: [],
        searchValue: '',
      });
    },
    showComparisonTableAction() {
      this.$router.push('/comparison');
    },
    closeMenu() {
      this.menu = false;
    },
    getScrollerHeight(elHeight) {
      const bannerHeight = 150;
      return bannerHeight + elHeight;
    },
    focusSearchField() {
      if (this.isUserSearch) {
        this.$refs.searchSchoolRegion.$el.querySelector('input[type="text"]').focus();
      } else {
        this.$refs.searchFields?.$el?.children[0][1]?.focus();
      }
    },
  },
  emits: ['scrollHeight', 'tabHeight'],
};
</script>
<style scoped>
.detailsButton {
  padding: 16px !important;
  min-width: 85px !important;
}

.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 13px !important;
}

.label-text {
  font-size: 16px !important;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
