<template>
  <v-chip
    v-bind="$attrs"
    v-on="$listeners"
    :aria-labelledby="id"
    tabindex="0"
    outlined
    :id="id"
    :aria-expanded="show ? 'true' : 'false'"
    :class="{'active': show || selected, 'chip': true}"
    :disabled="disabled"
    @click="show = !show"
    @keydown.enter="show = !show"
  >
    <span :id="`${id}-label`">
      {{ name }}
    </span>
    <v-icon
      v-if="selected && !mandatory"
      role="button"
      aria-label="clear filter"
      small
      :color="ADS_Colors.Light_Blue"
      class="clear-icon"
      @click.stop="$emit('clear:filter')"
      @keydown.enter.stop="$emit('clear:filter')"
    >
      close
    </v-icon>
    <v-icon
      v-else
      small
      class="ml-2"
      aria-hidden="true"
    >
      {{ !show ? 'arrow_drop_down' : 'arrow_drop_up' }}
    </v-icon>
  </v-chip>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core';

export default {
  name: 'Activator',
  props: {
    name: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ADS_Colors
    }
  },
  computed: {
    show: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        if (!value) { // false - close
          document.activeElement.blur(); // prevents focus state styles to keep displaying after the menu has closed
        }
        this.$listeners.input && this.$listeners.input(value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.v-chip {
  &.v-chip--outlined {
    &.v-chip {
      &.v-chip {
        box-sizing: border-box;
        border: 1px solid transparent;
        margin: 1px;

        .theme--light & {
          background-color: $ads-white !important;
          border-color: $ads-dark-70;
          color: $ads-dark-70;

          &:focus {
            border: 2px solid $ads-navy !important;
            margin: 0;
          }

          &.active {
            color: $ads-navy !important;
            background-color: $ads-light-blue !important;
            border-color: $ads-navy !important;
          }

          .clear-icon {
            box-sizing: border-box;
            border-radius: 50%;
            background-color: $ads-navy;
            color: $ads-light-blue;
            margin-left: 8px;


            &:focus {
              background-color: $ads-light-blue;
              color: $ads-navy !important;
              border: 1px solid $ads-navy;
            }
          }
        }

        // overriding the selected styles for dark theme
        .theme--dark & {
          background-color: $ads-dark !important;
          border-color: $ads-light-20;
          color: $ads-light-20;

          &:focus {
            border: 2px solid $ads-light-20 !important;
            margin: 0;
          }

          &.active {
            color: $ads-navy-dark !important;
            border-color: $ads-navy-dark !important;
          }

          .clear-icon {
            border-radius: 50%;
            background-color: $ads-navy-dark;
            color: $ads-dark !important;
            margin-left: 8px;

            &:focus {
              background-color: $ads-dark;
              color: $ads-navy-dark !important;
              border: 1px solid $ads-navy-dark;
            }
          }
        }
      }
    }
  }
}

</style>
