<template>
  <v-list
    ref="virtualList"
    :class="`${mobile ? 'mx-n0' : inFullScreen ? '' : 'mx-n5'} invisible-scrollbar`"
    :items="schoolResults"
    :max-width="mobile ? '100vw' : contentWidth"
    flat
    style="overflow-y: scroll; scrollbar-width: none"
    @scroll="onScroll()"
  >
    <v-list-item
      v-for="(item, index) in schoolResults"
      :key="`schoolCode_${item.schoolCode}`"
      :style="`border-bottom: 1px solid #ccc; min-height: 0`"
      class="pa-6 pt-5 flex-column"
    >
      <div class="d-flex justify-space-between" style="width: 100%; height: 100%">
        <div class="d-flex flex-column justify-space-between">
          <div>
            <button
              :aria-label="`Go to ${item.schoolFullName} school info dialog window`"
              :class="`${mobile ? 'mobile-label' : 'label-text'} font-weight-bold text-left`"
              style="color: #002664; cursor: pointer"
              @click="showResult(item)"
              @focus="loadOnLastFocused(index)"
            >
              {{ item.schoolFullName }}
            </button>
            <div
              v-if="item.address"
              :class="`${mobile ? 'mobile-text' : 'default-text'}`"
              style="word-break: break-word; color: #22272b"
            >
              {{ item.fullAddress }}
            </div>
          </div>
          <div>
            <div
              v-if="item.transferPoints"
              :class="`${mobile ? 'mobile-text' : 'default-text'} mt-2`"
              style="color: #22272b"
            >
              {{ item.transferPoints }}
              transfer points
            </div>
            <Chip
              v-if="item.isIncentive"
              :color="COLOURS.RENTAL_SUBSIDY.background"
              class="pa-1"
              dense
              label
              small
              text="Rental subsidy available"
              textColor="primary"
            >
              <span
                :class="`${mobile ? 'mobile-text' : 'default-text pt-1'} ma-0 pa-0`"
                style="font-weight: normal !important"
              >
                Rental subsidy available
              </span>
            </Chip>
          </div>
        </div>
        <div class="text-right d-flex flex-column justify-space-between">
          <div>
            <div v-if="item.isIncentive || item.isCC">
              <div v-if="item.isIncentive" class="mb-2">
                <Chip
                  :color="COLOURS.INCENTIVE.background"
                  :textColor="COLOURS.INCENTIVE.text"
                  small
                  text="INCENTIVE SCHOOL"
                >
                  <span :class="`${mobile ? 'mobile-text' : 'default-text'} font-weight-bold`"> INCENTIVE SCHOOL </span>
                </Chip>
              </div>
              <div v-if="item.isCC" class="mb-2">
                <Chip :color="COLOURS.CC.background" :textColor="COLOURS.CC.text" small text="CC SCHOOL">
                  <span :class="`${mobile ? 'mobile-text' : 'default-text'} font-weight-bold`"> CC SCHOOL </span>
                </Chip>
              </div>
            </div>
            <div>
              <div class="default-text" style="color: #22272b">Total major benefits</div>
              <div :class="`${mobile ? 'mobile-label' : 'label-text'}  font-weight-bold`">
                <span class="body-2" style="color: #22272b"> up to </span>
                <span class="label-text">
                  {{ `$${item.benefits?.total?.toLocaleString()}` }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grow text-right" style="width: 100%">
        <div class="mt-0">
          <AdsButton
            v-if="!compareList.find((school) => school.schoolCode === item.schoolCode)"
            :aria-label="`Add to compare ${item.schoolFullName}`"
            :disabled="numberOfCompare >= 5"
            :tertiary="true"
            button-text="Add to compare"
            class="subtitle-1"
            icon="add_circle_outline"
            style="padding: 0"
            @click="addToCompare(item)"
            @focus="loadOnLastFocused(index)"
          />
          <div v-if="compareList.find((school) => school.schoolCode === item.schoolCode)" class="d-flex">
            <span class="default-text .body-2 pr-2 grow align-self-center" style="color: #495054">
              Added to compare
            </span>
            <AdsButton
              :aria-label="`Remove ${item.schoolFullName}`"
              :tertiary="true"
              button-text="Remove"
              class="font-weight-bold pr-0 removeSchool subtitle-2 primary--text"
              style="padding: 0; padding-left: 2px"
              @click="removeFromCompare(item)"
            />
          </div>
        </div>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
import { AdsButton, Chip } from '@nswdoe/doe-ui-core';
import Scroll from 'vuetify/src/directives/scroll';

import { COLOURS, SCROLLER_PAGE_SIZE } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'ListScroller',
  components: {
    Chip,
    AdsButton,
  },
  directives: { Scroll },
  props: {
    results: {
      type: Array,
      default: () => {
        return [];
      },
    },
    contentWidth: {
      type: String,
      default: '475px',
    },
    height: {
      type: String,
      default: '0',
    },
    inFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      COLOURS,
      schoolResults: undefined,
    };
  },
  watch: {
    results: {
      handler() {
        if (Array.isArray(this.results)) {
          this.setSchoolResults();
        } else {
          this.schoolResults = undefined;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      numberOfCompare: 'numberOfCompare',
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    compareList() {
      return this.$store.state.compareList;
    },
  },
  methods: {
    onScroll() {
      const scrollTop = this.$refs.virtualList?.$el?.scrollTop;
      const scrollTopMax = this.$refs.virtualList?.$el?.scrollTopMax;
      const totalChildrenHeight = this.$refs.virtualList?.$children?.reduce((acc, curr) => {
        return acc + curr?.$el.clientHeight;
      }, 0);
      const triggerHeight = (totalChildrenHeight / this.schoolResults.length) * this.schoolResults.length * (3 / 4);

      if (scrollTop === scrollTopMax || scrollTop > triggerHeight) {
        this.setSchoolResults(this.schoolResults?.length / SCROLLER_PAGE_SIZE + 1);
      }
    },
    loadOnLastFocused(index) {
      if (index === this.schoolResults?.length - 1) {
        this.setSchoolResults(this.schoolResults?.length / SCROLLER_PAGE_SIZE + 1);
      }
    },
    setSchoolResults(page = 1) {
      this.schoolResults = this.results.slice(0, page * SCROLLER_PAGE_SIZE);
    },
    showResult(item) {
      item.lat
        ? this.$store.dispatch('setShowInfo', {
            pan: true,
            item,
          })
        : this.$store.dispatch('setShowInfo', {
            pan: false,
            item,
          });
    },
    addToCompare(school) {
      this.$emit('compareSchool');
      this.$store.dispatch('addToCompare', school.schoolCode);
    },
    removeFromCompare(school) {
      this.$store.dispatch('removeFromCompare', school);
    },
  },
};
</script>

<style scoped>
.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.removeSchool::v-deep.v-btn:not(.v-btn--round).v-size--default {
  min-width: unset !important;
  padding: 0 !important;
}

.default-text {
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
}

.mobile-text {
  font-size: 14px !important;
}

.label-text {
  font-size: 20px !important;
}

.mobile-label {
  font-size: 14px !important;
}
</style>
